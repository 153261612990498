<template>
  <div
    class="
      bg-primary-5
      p-4
      py-5
      border-r-10
      d-flex
      flex-column
      justify-content-between
    "
  >
    <skeleton
      v-if="getCurrentLibrary.isLoading"
      borderRadius="10px"
      height="2rem"
      class="mb-3 skeleton-dark"
    />

    <div v-if="!getCurrentLibrary.isLoading">
      <h2 class="text-primary-0 fs-4 text-center fw-bold mb-3">
        Your current <br />
        level is:
      </h2>
      <h1 class="fw-bold text-center text-accent-1 level-current">
        {{ getCurrentLibrary.currentLevel.level_name_short }}
      </h1>
      <p
        v-if="getCurrentLibrary.currentLevelNext"
        class="text-center text-primary-0 px-4"
      >
        To advance to level
        {{ getCurrentLibrary.currentLevelNext.level_name_short }}, you need to
        pass the {{ getCurrentLibrary.currentLevel.level_name_short }} test.
      </p>
      <p v-else class="text-center text-primary-0">
        Your are in the last level
      </p>
      <h1 class="text-center fs-4 px-5 text-primary-0 fw-bold mt-3">
        Your total progress:
      </h1>
      <div class="d-flex flex-column align-items-center mt-3">
        <circle-progress
          empty-color="#74DAD9"
          fill-color="#00a1a0"
          :is-bg-shadow="false"
          :percent="
            (getCurrentLibrary.currentLevel.complete * 100) /
            getCurrentLibrary.currentLevel.all
          "
          :show-percent="true"
          :transition="1000"
          :border-width="5"
          :border-bg-width="8"
          :size="120"
        />

        <h1 class="mt-2 fs-4 text-center">Bright Path</h1>
      </div>
    </div>
    <button
      @click="goToBook"
      v-if="!getCurrentLibrary.isLoading"
      class="
        btn
        text-white
        bg-secondary-1 bg-hover-secondary-1
        mt-5
        py-2
        bl-shadow
        w-100
      "
    >
      Schedule a class
    </button>
    <template v-if="getCurrentLibrary.isLoading">
      <div class="mb-4" v-for="item in 7" :key="item">
        <skeleton
          borderRadius="10px"
          height="1.2rem"
          class="mb-2 skeleton-dark"
        />
        <skeleton
          borderRadius="10px"
          height="1.6rem"
          class="mb-1 skeleton-dark"
        />
      </div>
    </template>
  </div>
</template>

<script>
import useCurrentLibrary from "@/shared/composables/Library/useCurrentLibrary";
import insertPercentage from "@/shared/utils/changeTexts/insertPercentageToProgress";
import { useRouter } from 'vue-router'
export default {
  name: "LibraryCurrentComponent",
  setup() {
    const { getCurrentLibrary } = useCurrentLibrary();
    const router = useRouter()

    const goToBook = () => {
      router.push({ name: 'student-teachers-scheduled-class' })
    }

    return {
      goToBook,
      getCurrentLibrary,
      insertPercentage,
    };
  },
};
</script>

<style lang="scss" scoped>
.level-current {
  font-size: 5rem;
  line-height: 4rem;
}
</style>