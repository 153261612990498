<template>
  <transition name="fade">
    <div
      v-if="showLibrary"
      class="bg-white p-3 p-sm-4 border-r-10 library-level"
      :class="classCurrentLevel"
    >
      <div
        v-if="!getCurrentLibrary.isLoading"
        class="
          level-header
          d-flex
          align-items-center
          justify-content-between
          mb-3
        "
      >
        <h1 class="level-title fw-bold">
          {{ getCurrentLibrary.currentLevel.level_name_short }}
        </h1>
        <div
          class="
            current-level
            d-flex
            gap-3
            justify-content-center
            align-items-center
            bg-primary-1
            rounded-pill
            py-2
            px-4
            text-white
          "
        >
          <i
            class="pi pi-chevron-left pointer"
            v-if="getCurrentLibrary.indexLevel > 0"
            @click="showLevel('previous')"
          ></i>
          <span>{{ getCurrentLibrary.currentLevel.level_name }}</span>
          <i
            class="pi pi-chevron-right pointer"
            v-if="
              getCurrentLibrary.indexLevel < getCurrentLibrary.levels.length - 1
            "
            @click="showLevel('next')"
          ></i>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between" v-else>
        <skeleton
          borderRadius="10px"
          height="7rem"
          class="skeleton-light mb-3"
          width="8rem"
        />
        <skeleton
          borderRadius="20px"
          height="2.5rem"
          class="skeleton-light"
          width="9rem"
        />
      </div>

      <template v-if="getCurrentLibrary.isLoading">
        <div v-for="i in 2" :key="i" class="mb-4">
          <skeleton
            borderRadius="10px"
            height="1.6rem"
            width="16rem"
            class="mb-3 skeleton-light"
          />
          <skeleton
            v-for="i in 4"
            :key="i"
            borderRadius="10px"
            height="1.6rem"
            width="25rem"
            class="mb-2 skeleton-light"
          />
        </div>
      </template>
      <template v-else>
        <accordion
          class="accordion-level"
          v-for="(level, index) in getCurrentLibrary.currentLevel.level_content"
          :key="level.title_id"
          :activeIndex="0"
        >
          <accordion-tab>
            <template #header>
              <div class="d-flex gap-3 align-items-center">
                <div class="circle-progress">
                  {{
                    userAuthenticated.role === "student"
                      ? (level.complete !== undefined ? level.complete + "/": "0/")
                      : ""
                  }}{{ level.title_content.length }}
                </div>
                <h4 class="mb-0 fw-bold">
                  {{ level.title_name }}
                </h4>
              </div>
            </template>
            <div class="accordion-level-content">
              <p
                class="fs-5 pb-3 pointer"
                v-for="(subLevel, index2) in level.title_content"
                :key="index2"
                @click="
                  goLevelContent(
                    level.title_name,
                    subLevel,
                    index,
                    level.title_id,
                    index2
                  )
                "
              >
                {{ subLevel.subtitle_name }}
              </p>
            </div>
          </accordion-tab>
        </accordion>
      </template>
    </div>
  </transition>

</template>

<script>
import useCurrentLibrary from "@/shared/composables/Library/useCurrentLibrary";
import usePrivateLayout from "@/shared/composables/Layouts/usePrivateLayout";
export default {
  name: "LibraryLevelComponent",
  setup() {
    const { getCurrentLibrary, goLevelContent, showLevel, classCurrentLevel ,showLibrary } =
      useCurrentLibrary();
    const { userAuthenticated } = usePrivateLayout();
    return {
      showLibrary,
      goLevelContent,
      getCurrentLibrary,
      userAuthenticated,
      showLevel,
      classCurrentLevel,
    };
  },
};
</script>

<style lang="scss" scoped>
.level-title {
  font-size: 6rem;
}
</style>
