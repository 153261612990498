<template>
  <h1 class="fs-1 fw-bold text-primary-1">Bright Path</h1>
  <div class="mt-4 grid-2">
    <!-- <general-progress-component /> -->
    <library-level-component />
  </div>
</template>

<script>
// import GeneralProgressComponent from "@/modules/teachers/components/MyLibrary/GeneralProgressComponent";
import LibraryLevelComponent from "@/shared/components/Library/LibraryLevelComponent";
import useLibrary from "@/modules/teachers/composables/MyLibrary/useLibrary";
export default {
  name: "MyLibraryView",
  components: {
    // GeneralProgressComponent,
    LibraryLevelComponent,
  },
  setup(){
    const { loadServices } = useLibrary()
    loadServices()
    return {
    }
  }
};
</script>

<style lang="scss" scoped>
.grid- {
  display: grid;
  grid-template-columns: 20rem 1fr;
  gap: 1rem;
}
</style>